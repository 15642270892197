import service_img from "../../assets/images/services-img.webp";
import ViewMoreIcon from "../../assets/icons/view-arrow.svg";
import { Link } from 'react-router-dom'
import { Services } from "../../data"
import { AiOutlineSetting } from "react-icons/ai";
import './HomeServices.css'

const HomeServices = (props) => {
    const NavigateClick = () => {
        window.scrollTo(0, 0);
      };
    return (
        <div className="container services__container">
            <div className="centre-heading" data-aos="fade-right">
                <div className="top-heading">
                    <h5><AiOutlineSetting /> {props.title}</h5>
                </div>
                <h2>{props.heading}</h2>
            </div>
            <div className="services__wrap">
                <div className="service__img" data-aos="fade-right">
                    <img src={service_img} alt="Helper Adda Services" />
                </div>
                <div className="service__content">
                    <div className="explore__more" data-aos="fade-up">
                        <Link to="/services" onClick={NavigateClick}><h6>View More <img src={ViewMoreIcon} alt="View More" /></h6></Link>                        
                    </div>
                    <div className="services__card__wrap" data-aos="fade-right">
                        {Services.map(({ name, srclink, path }, index) => {
                            return (
                                <div className="service__card" key={index}>
                                    <Link to={path} onClick={NavigateClick}>
                                        <img src={srclink} alt={name} className="service-img" />
                                        <h5>{name}</h5>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeServices
import { StrictMode } from "react"

// =======Components========
import ContactFormSection from "../../ui/contact_components/form_section";

const Contact = () => {
  return (
    <StrictMode>
      <ContactFormSection/>

    </StrictMode>
  )
}

export default Contact
import { TbArrowBigUpLinesFilled } from 'react-icons/tb'
import {BsWhatsapp} from 'react-icons/bs'
// import {BiLogoGmail} from 'react-icons/bi'

// ======Top to Up Function =====
const BtnFunctionality = () => {
    const TopToUpClick = () => {
        window.scrollTo(0, 0);
    };

// ======Whatsapp Btn Function =====

    var phone_number = "+919319194642"
    var message = "Hello! Helper Adda"
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone_number}&text=${message}`;

    const WhatsappTap = () => {
        window.open(whatsappUrl, "_blank");
    };

// ======Email Btn Function =====

    // const MailTap = () => {
    //     window.scrollTo(0, 0);window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')
    // };

    return (
        <div className=".BtnFunctionality">
            <div className="TopToUpBtn" id='TopToUpBtn' onClick={() => TopToUpClick()} data-aos="fade-up"><TbArrowBigUpLinesFilled /></div>
            <div className="WhatsappTap" id='WhatsappTap' onClick={() => WhatsappTap()} data-aos="fade-up"><BsWhatsapp/></div>
            {/* <div className="MailTap" id='MailTap' onClick={() => MailTap()} data-aos="fade-left"><BiLogoGmail/></div> */}
        </div>
    )
}

export default BtnFunctionality
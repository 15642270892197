import React, { StrictMode } from "react";

//======Components =======
import DownloadApp from "../../ui/download_app_components/download_app";
import AppStore from "../../ui/download_app_components/app_store";

const Download = () => {
  return (
    <StrictMode>
      <DownloadApp
        title="Download Helper Adda"
        heading="Download Helper Adda App!"
        desc_1="Download the Helper Adda app from the Play Store, create an account, and if you want to join as a service provider, click on that, or if you want to book services, click on the customer option, and you will get the service at your doorstep"
      />
      <AppStore />
    </StrictMode>
  );
};

export default Download;

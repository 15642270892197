import "./HomeBookDemo.css";
import "./form.css";
// import { BsFillPersonFill } from "react-icons/bs";
import CallImg from "../../assets/icons/services-icons/call-img.webp";
import RatingImg from "../../assets/icons/services-icons/rating_img.webp";
import emailjs from "@emailjs/browser";
import React, { useRef } from "react";

const HomeBookDemo = (props) => {
  const form = useRef();
  const formreset = () => {
    console.log("Reset work");
    document.getElementById("first_name").value = "";
    document.getElementById("last_name").value = "";
    document.getElementById("current_addresss").value = "";
    document.getElementById("any_query").value = "";
    document.getElementById("form_email").value = "";
    document.getElementById("form_phone").value = "";
    document.getElementById("message").value = "";
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "dharmender",
      "helperadda_homepage",
      form.current,
      "zxsfDm0Yu9qguwoMo"
    );
    document.getElementById("SuccesFullySend").style.display = "flex";
    formreset();
  };
  return (
    <div className="container homebookdemo__Container">
      <div className="bookdemo_content" data-aos="fade-right">
        <h5>{props.title}</h5>
        <h2>{props.heading}</h2>
        <p className="text">{props.desc}</p>
        <div className="call_section">
          <div className="call_img">
            <img src={CallImg} alt="call" />
          </div>
          <div className="call_text">
            <h4>Give a call for any query</h4>
            <p>{props.contact_no}</p>
          </div>
        </div>
        <div className="rating_section" data-aos="fade-right">
          <div className="rating_img">
            <img src={RatingImg} alt="Rating Img" />
          </div>
          <div className="rating_content">
            <h3>Trust Score Rated</h3>
            <p>Rated by over 200+ customers</p>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        {/* Contact Form */}
        <form ref={form} onSubmit={sendEmail} className="booking-form background-image" data-aos="fade-right">
          <h3 className="">Get Touch With Us</h3>
          <div className="row">
            <div className="form-group col-sm-6">
              <input
                type="text"
                className="form-control"
                name="fname"
                id="first_name"
                placeholder="First Name" required autoComplete="true" 
              />
            </div>
            <div className="form-group col-sm-6">
              <input
                type="text"
                className="form-control"
                name="lname"
                id="last_name"
                placeholder="Last Name" required autoComplete="true"
              />
            </div>
            <div className="form-group col-sm-6">
              <input
                type="text"
                className="form-control"
                name="current_addresss"
                id="current_addresss"
                placeholder="Your Addresss" required
              />
            </div>
            <div className="form-group col-sm-6">
              <input
                type="text"
                className="form-control"
                name="any_query"
                id="any_query"
                placeholder="Any Query?" required
              />
            </div>
            <div className="form-group col-sm-6">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                id="form_email" required autoComplete="true"
              />
            </div>
            <div className="form-group col-sm-6">
              <input
                type="number"
                className="form-control"
                name="user_phone"
                id="form_phone"
                placeholder="Phone Number" required
              />
            </div>
            <div className="form-group col-12">
              <textarea
                cols="40"
                rows="10"
                className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                aria-required="true"
                aria-invalid="false"
                placeholder="Message"
                name="message"
                spellCheck="false"
                id="message"
              ></textarea>
            </div>
            <div className="form-btn col-12">
              <button className="btn" value="Send" type="submit">
                Submit
              </button>
              <div className="SuccesFullySend" id="SuccesFullySend">
                Your Message Successfully Send !
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HomeBookDemo;

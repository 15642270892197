import './Policies.css'
export const PrivacyPolicy = () => {
    return (
        <div className="container Privacy_policy_Container">
            <div className="centre-heading mb-3">
                <div className="top-heading">
                    <h5>Privacy Policy</h5>
                </div>
                <h1>Our Privacy Policy Puts Your Privacy First</h1>
            </div>
            <div className="content__wrapper">
                <p>Shailasia Management Pvt Ltd and/or Helper Adda as its brand, (We) take privacy very seriously. We are dedicated to treating your personal information with care and respect. Our Privacy Policy (Policy) is designed to provide transparency into our privacy practices and principles, presented in a format that makes it easier for our guests to navigate, read and understand the contents of our Website, www.helperadda.com(Website). We have accordingly developed this Policy to protect and keep your personal information confidential.</p>
                <h6>Personal Information</h6>
                <p>We thank you (Customer/User/You/Your) for registering with the company name and/or Helper Adda as its brand (We/Us/Our/Helper Adda) on www.helperadda.com(Website) as well as on our Mobile Application (App). Your agreement with us includes these user terms and conditions of use (Terms) and our privacy policy (together with the Policies). If you don't agree with these terms, please don't use our services (defined later). Please note that you can only book services Helper Adda offers if you have clicked and accepted these terms.</p>
                <p><strong>1.1 </strong> We categorize information about you (collectively referred to as "Personal Information") as follows:</p>
                <p><strong>A.Profiling Information:</strong> Information which you provide when you register for a service, which may include some or all of the following: - Information about your personal identity such as gender, name, job title, address, marital status, age, preferences, likes and dislikes etc.; - your financial information such as your banking details and any information relating to your lifestyle levels; and - your contact details such as your physical addresses, postal addresses, telephone and fax numbers and the like. In the case of Facebook-aligned services (if so opted by you), publicly available information about your friends, their likes and dislikes, etc.</p>
                <p><strong>B. Payment and Account Information:</strong> Your account history with us, including (without limitation) all billing information and communications, payment history etc. We maintain this in encrypted form on secure servers.</p>
                <p><strong>C. Service Usage: </strong> Information about your navigation using our services, for example, the URLs of websites that you visit and from which you request downloads.</p>
                <p><strong>D. Log Information:</strong> Information such as your web request, IP address, browser type, browser language, and the date and time of the request. Helper Adda may record calls for quality and training purposes in case of booking via call.</p>
                <p><strong>E. Transactional Information:</strong> Transactional history (other than banking details) about your e-commerce activities.</p>
                <p><strong>F. Correspondence Information:</strong> Content, Information about your correspondents, and the destination/origin of communications between you and any other person using our services, which include email communications, blog, chat room and discussion board communications, instant message communications, experts forum communications, fax mail communications, membership of mailing lists etc.</p>
                <p><strong>G. User IDs:</strong> Your usernames, passwords, email addresses and other security-related information used by you in relation to our services.</p>
                <p><strong>H. Stored Information:</strong> Data either created by you or by a third party and which you wish to store on our servers, such as image files, documents etc. We only collect your personal information to conduct our business and to enable us to deliver and improve our services.</p>
                <p><strong>1.2 </strong> We do not and shall never, for any reason whatsoever, sell your Personal Information to any third party or otherwise trade on it. We will only disclose your Personal Information in accordance with this Policy. If we want to use it for any other purpose, we will obtain your prior written consent. If you decline to submit personal information to us, then we will unfortunately not be in a position to provide the Services to you. All the information provided by you to us is to an open, public environment or forum that third parties may access. Third parties may collect and collate and use such information for their own purposes. You should accordingly be careful when deciding to share any of your Personal Information in such public environments. Information disclosed publicly is also shared with our affiliates, third-party service providers, and sponsors of competitions unless expressly stated otherwise. We are not liable to you or any third party for any damages that you or any third party may suffer, however arising from your disclosure of Personal Information in any public environment. You accordingly disclose information in a public environment at your own risk.</p>

                <h6>Right to Collect</h6>
                <p>By accepting the Terms of Service, you agree that we may collect and store your Personal Information as long as you use our Services subject to the limitations set out in this Policy.</p>
                <p><strong>2.1 </strong> We collect your Profiling and Account Information for the following reasons:</p>
                <p><strong>A :</strong> We need your identity details, contact details, bank information and account history to manage our relationship with you and provide Services to you. We can use this information to suggest products that you like on our Website. We will only disclose this information as provided below.</p>
                <p><strong>B :</strong> We use certain of your information in an aggregated form to compile statistical and demographic profiles for our business and marketing activities. We may disclose such information about you, provided that the information is in an aggregated form that is not capable of being used or interpreted in such a manner as to identify you.</p>

                <p><strong>2.2 </strong> We collect and store your service usage and transactional information to:</p>
                <p><strong>A :</strong> Determine and verify the service charges payable by you and to administer our relationship with you.</p>
                <p><strong>B :</strong>  Comply with any statutory or regulatory requirement.</p>
                <p><strong>C :</strong>  Compile statistical and demographic profiles about you for our business and marketing activities and to customize our services to you. While we are entitled to use such information about you for our own internal business purposes without limitation, we will only disclose it in an aggregated form that is not capable of being used or interpreted in such a manner as to identify you.</p>
                <p><strong>D :</strong>  Monitor your use of our Services for the purposes of ensuring compliance with our terms of Service.</p>
                <p><strong>E Surveys:</strong>  We may occasionally conduct voluntary surveys of visitors to the Website to obtain aggregate demographic information about them. Such information may be compiled and shared in the aggregate with advertisers and other third parties.</p>
                <p><strong>2.3 </strong> We collect and store your correspondence information and personal identifiers to: A. Comply with our obligations under the law. B. Monitor your use of our Services in order to ensure your compliance with our Terms of Service.</p>
                <p><strong>2.4 </strong> Any Personal Information which we collect and which we may use in an aggregated format, ensuring you are not individually identified, is our property. We may use it, in our sole discretion and without any compensation to you, for any legitimate purpose, including (without limitation) the commercial sale thereof to third parties. Sometimes we use "cookies" so that we can provide you with more customized information when you return to our Website. "Cookies" are used to store user preferences and track user trends to enhance your interactive experience and generally improve our Services to you. You can set your browser to notify you when you are sent a "cookie", giving you a chance to decide whether or not to accept it. If you do accept a "cookie", you thereby agree to our use of any personal information collected by us using that Cookie. You may update your profiling information at any time on the Website. You acknowledge and agree that in the interests of improving personalization and service efficiency, we may, under controlled and secure circumstances, share your personal information with our affiliates (an entity that is our subsidiary or holding company or a subsidiary of our holding company or an entity which controls, is controlled by or is under common control with us).</p>
                <p><strong>2.5 Physical safety/emergency alerts to send SMS: </strong> In addition, tracking information will be collected as you navigate through our Services, including, but not limited to geographic areas. In case of emergency your mobile phone will send your GPS coordinates and an SMS, during the Service, to our servers. Most GPS-enabled mobile devices can define one's location to within 50 feet.</p>
                <p><strong>2.6 Information collected by mobile application: </strong> Our Services are primarily provided through the Mobile Application. We may collect and use technical data and related information, including but not limited to, technical information about your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to you (if any) related to such Mobile Applications.</p>

                <p>When you use any of our Mobile Applications, the Mobile Application may automatically collect and store some or all of the following information from your mobile device ("Mobile Device Information"), in addition to the Device Information, including without limitation:</p>
                <p><strong>A :</strong> Your preferred language and country site (if applicable)</p>
                <p><strong>B :</strong> The manufacturer and model of your mobile device</p>
                <p><strong>C :</strong> Your mobile operating system</p>
                <p><strong>D :</strong> The type of mobile internet browsers you are using</p>
                <p><strong>E :</strong> Your geolocation</p>
                <p><strong>F :</strong>  Information about how you interact with the Mobile Application and any of our websites to which the Mobile Application links, such as how many times you use a specific part of the Mobile Application over a given time period, the amount of time you spend using the Mobile Application, how often you use the Mobile Application, actions you take in the Mobile Application and how you engage with the Mobile Application</p>

                <p>Information to allow us to personalize the services and content available through the Mobile Application</p>
                <p>Data from SMS/ text messages upon receiving Device permissions for the purposes of (i) issuing and receiving one-time passwords and other device verification, and (ii) automatically filling verification details during financial transactions, either through us or a third-party service provider, in accordance with applicable law. We do not share or transfer SMS/ text message data to any third party other than as provided under this Privacy Policy.</p>

                <h6>General Exceptions</h6>
                <p>If we are required to intercept, disclose, monitor and/or store your Personal Information: (a) by law; (b) to conduct our business; (c) to secure our systems; or (d) to enforce our own rights, we will do so in the manner as prescribed by law. Such interception, disclosure, monitoring and storage may take place without your knowledge. In that case, we will not be liable to you or any third party for any damages, however arising from such interception, disclosure, monitoring and storage. In order to ensure that all our users comply with the Terms of Service, we may monitor your personal information to the extent that this may be required to determine compliance and/or to identify instances of non-compliance. To ensure that the security and integrity of our Services are safeguarded, we may monitor your personal information. This monitoring may include (without limitation) the filtering of incoming and outgoing electronic data messages to identify, limit and/or prevent the transmission of spam, viruses and/or unlawful, defamatory, obscene or otherwise undesirable material or content. We may under certain circumstances procure an element of the services from a third-party service provider. To the extent that it may be necessary, and solely for the purposes of providing the Service to you, you agree that we may disclose to such a third party any of your Personal Information that may be necessary for the procurement of services from the third party.</p>

                <h6>Collection Of Automation Information, Use Of Cookies And Other Tracking Devices</h6>
                <p>We and our third-party service providers, which include ad networks, use cookies, web beacons, and other tracking technologies to collect information about your use of our Website/App and Services, such as your browser type, your ISP or operating system, your domain name, your access time, the URL of the previous Website you visited, your page views, your IP address, and the type of device that you use. We also track how frequently you visit our Website/app and use our Services. We use this information (including the information collected by our third-party service providers) for Website/app analytics (including to determine which portions of our Website/app are used most frequently and what our users like/do not like), to assist us in determining relevant advertising (both on and off our Website/app), to evaluate the success of our advertising campaigns, and as otherwise described in this Policy. Currently, we do not honour browser requests not to be tracked.</p>

                <h6>Third-Party Ad Network</h6>
                <p>We participate in third-party ad networks that may display advertisements on other websites based on your visits to our Site/app and other websites. This enables these third parties and us to target advertisements by displaying ads for products and services in which you might be interested. Third-party ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other technologies to measure the effectiveness of their ads and to personalize advertising content to you. These third-party cookies and other technologies are governed by each third party's specific privacy policy and not by Helper Adda's Policy. We may provide these third-party advertisers with information about your usage of our Site/app and our services and aggregate or non-personally identifiable information about visitors to our Site and users of our Service.</p>

                <h6>What Choices Do I Have Regarding the Use of My Personal Information?</h6>
                <p>We may send periodic promotional or informational emails/SMS to you. You may opt out of such communications by following the opt-out instructions contained in the email. Please note that it may take up to 10 business days for us to process opt-out requests. Suppose you opt out of receiving emails/SMS about recommendations or other information we think may interest you. In that case, we may still send you emails/SMS about your Account or any Services you have requested or received from us.</p>

                <h6>Refer & Earn</h6>
                <p>Our Website/app users can voluntarily choose to electronically forward a link, page, or invite to someone else by clicking "Refer & Earn." To do so, the user can send the link by email, WhatsApp, telegram or any other electronic means. The information is not used for any other purpose.</p>

                <h6>Collection Of Personal Information From Social Networking Sites</h6>
                <p>Our Website/app users can voluntarily choose to electronically forward a link, page, or invite to someone else by clicking "Refer & Earn." To do so, the user can send the link by email, WhatsApp, telegram or any other electronic means. The information is not used for any other purpose.</p>

                <h6>Security</h6>
                <p>We endeavour to comply with the laws of other countries but cannot and do not warrant that we do. We do everything we reasonably can to protect your rights of Privacy on systems and the Website controlled by us, but we are not liable for any unauthorized or unlawful disclosures of your personal and confidential information made by third parties who are not subject to our control, for example, advertisers and websites that have links to our Website. You should note that the information and privacy practices of our business partners, advertisers, sponsors or other sites to which we provide hyperlinks may differ from ours. This Policy is effective as of the date mentioned hereinabove. At our sole discretion, we may change any or all of the terms of this Policy by updating this document from time to time. Please review this Policy periodically to ensure you are aware of any changes. If you have any questions regarding this Policy, please feel free to drop us a line at support@helperadda.com</p>

                <h6>Changes to the Privacy Policy</h6>
                <p>Occasionally, we may update this Privacy Policy to reflect changes to our information practices. Any changes will be effective immediately upon posting the revised Privacy Policy. Please periodically review this page for the latest information on our privacy practices.</p>

                <h6>Background And Key Information</h6>
                <p><strong>(a) About this Policy:</strong> This Policy is for the people who access or avail of the services. To clear the doubts, this Policy references "you" as an end user that uses the platform.</p>
                <p>When you use this platform, you consent to access, assort, stockpile and utilize your information as described by you and gathered by us.</p>

                <p><strong>(b) Review and Updates:</strong> We suggest you keep reviewing this Policy regularly as we continue to update our Privacy Policy as per the needs. To avoid any discrepancies, make sure that all the personal data provided by you is current and accurate.</p>
                <p>If there is a change in your personal data, please let us know to maintain a smooth and lasting relationship with us.</p>

                <p><strong>(c) Third-Party Services:</strong> This platform may contain links to third-party applications, websites, and services. By clicking on those links, you give your consent to a third party to collect and use your personal data.</p>
                <p>We don't endorse or control these Third-Party Services. So, we are neither sure nor accountable for their privacy policy.</p>
                <p>We suggest you read the privacy policy of third-party service providers anytime you access or switch to a third-party platform from our platform.</p>

                <p><strong>(d) </strong> Our Partner App uses background location information to share the partner's location with the customer so that the customer can track the service provider in real-time.</p>

                <h6>Data Retention</h6>
                <p>In accordance with our privacy policy, you give your consent to collect, use, store and share your personal data with our affiliates or other third-party service providers. We may use information and data pertaining to providing services, analytics, trend identification, and statistics to further enhance our services' efficiency. We may use your personal data for the provision of beneficial schemes, new offers, as well as for user experience enhancement.</p>
                <p>Subject to applicable laws, we may be directed by law enforcement agencies or the government and related bodies to disclose your personal data in connection with criminal or civil proceedings. Even if you discontinue accessing the services or terminate your account, we may use the data to comply with our legal rights and obligations.</p>
                <p>Helper Adda reserves the right to share the data with relevant agencies or bodies when needed. It will not be considered an infringement of the right to Privacy.</p>

                <h6>Changes to the Privacy Policy</h6>
                <p>From time to time, we may update this Privacy Policy to reflect changes to our information practices. Any changes will be effective immediately upon the posting of the revised Privacy Policy. We encourage you to periodically review this page for the latest information on our privacy practices.</p>

                <h6>Grievance Redressal</h6><br />
                <p><strong>(a) </strong> You may contact our designated Grievance Redressal Officer with any complaints or queries relating to the Services or these Terms and conditions:</p>
                <p><strong>Name :</strong> Munmun Singh</p>
                <p><strong>Designation :</strong> Founder</p>
                <p><strong>Operations Email Address :</strong> support@helperadda.com</p> <br />

                <p><strong>(b) </strong> We shall ensure that your complaint is resolved within the timelines advised by applicable laws.</p>


            </div>


        </div>
    )
}

export default PrivacyPolicy
import AboutUs from "../../assets/images/about-us.webp";
import AboutInner from "../../assets/images/about-img-1.webp";
import { AiOutlineCheckCircle } from "react-icons/ai";
import './HomeAbout.css'

const HomeAbout = (props) => {
    return (
        <div className="container about__container">
            <div className="part-2  about__image about_img"  data-aos="fade-right">
                <img src={AboutUs} alt="Helper Adda" />
            </div>
            <div className="part-1 about__content " data-aos="fade-left">
                <small></small>
                <h4>{props.title}</h4>
                <h2><span style={{color:'var(--primary)'}}>Helper Adda,</span> Find a reliable domestic helper in Real time.</h2>
                <p>{props.desc_1}</p>
                <div className="about_inner">
                    <div className="inner-1">
                        <img src={AboutInner} alt="Helper Adda" />
                    </div>
                    <div className="inner-2">
                        <li><AiOutlineCheckCircle color="#32c6f4" /> Easy & Emergency Solutions Anytime</li>
                        <li><AiOutlineCheckCircle color="#32c6f4" /> Getting Services at Affordable price</li>
                        <li><AiOutlineCheckCircle color="#32c6f4" /> More Reliable & Experienced Teams</li>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default HomeAbout
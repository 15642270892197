import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaLogic = ({ title, description, canonicalUrl }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaLogic;
import "./upcomming_components.css";
import GuardImg from "../../assets/images/services_img/guard.webp";
import LaundryImg from "../../assets/images/services_img/lundary.webp";
import DriverImg from "../../assets/images/services_img/driver.webp";
import BikeRideImg from "../../assets/images/services_img/bike.webp";
import RickshawImg from "../../assets/images/services_img/rickshaw.webp";
import DeliveryImg from "../../assets/images/services_img/delivery.webp";
import RoadSideImg from "../../assets/images/services_img/roadside.webp";
import { AiOutlineSetting } from "react-icons/ai";

const UpcomingSerivces = (props) => {
  return (
    <div className="container upcomming_services">      
        <h6 data-aos="fade-right"><AiOutlineSetting /> {props.title}</h6>
        <h2 data-aos="fade-right">{props.heading}</h2>      
      <div className="upcomming_section" data-aos="fade-right">
        <div className="new_services">
          <div className="upcomming_img">
            <img src={GuardImg} alt="GuardImg" />
          </div>
          <h5>Security Guard</h5>
        </div>
        <div className="new_services">
          <div className="upcomming_img">
            <img src={LaundryImg} alt="LaundryImg" />
          </div>
          <h5>Laundry Service</h5>
        </div>
        <div className="new_services">
          <div className="upcomming_img">
            <img src={DriverImg} alt="DriverImg" />
          </div>
          <h5>Driver Service</h5>
        </div>
        <div className="new_services">
          <div className="upcomming_img">
            <img src={BikeRideImg} alt="BikeRideImg" />
          </div>
          <h5>Bike Ride</h5>
        </div>
        <div className="new_services">
          <div className="upcomming_img">
            <img src={RickshawImg} alt="RickshawImg" />
          </div>
          <h5>Rickshaw Ride</h5>
        </div>
        <div className="new_services">
          <div className="upcomming_img">
            <img src={DeliveryImg} alt="DeliveryImg" />
          </div>
          <h5>Delivery Service</h5>
        </div>
        <div className="new_services">
          <div className="upcomming_img">
            <img src={RoadSideImg} alt="RoadSideImg" />
          </div>
          <h5>Roadside Assist</h5>
        </div>
      </div>
    </div>
  );
};
export default UpcomingSerivces;

// import './services.css'
import { StrictMode } from "react";

//===== Components=====
import HomeBookDemo from "../../ui/service_provider_components/HomeBookDemo";
import MetaLogic from '../../metalogic'

// import {ServicePagesSecSecond} from '../../data';

const ServicesProvider = () => {
  return (
    <StrictMode>
      <MetaLogic title={"Hiring professional service providers who want to make extra income"} description={"If you are looking for a part time job and want to earn extra income then you can connect with Helper Adda."} canonicalUrl={"https://helperadda.com/services-provider"} />
      <HomeBookDemo
        desc="We'd like to extend an invitation to you to see Helper Adda in action at your earliest convenience. With you, our professionals will go over the key features and functions and address any queries you may have.
        Please let us know which day and time are most convenient for you, so that we can make the necessary arrangements."
        title="Want to join us as services provider?"
        heading="Let's Scheduled a demo!"
        contact_no="+91 9518268109"
      />
    </StrictMode>
  );
};

export default ServicesProvider;

const CustomBtnFunctionality = () => {
    window.onscroll = function() {
        var pageOffset = window.scrollY;
        // var mailbtn = document.getElementById('MailTap');
        var whatsappbtn = document.getElementById('WhatsappTap');
        var topbtn = document.getElementById('TopToUpBtn');
        var vh = document.documentElement.clientHeight * 0.01;
      
        if (pageOffset >= vh) {
            // mailbtn.style.visibility = 'visible';
            whatsappbtn.style.visibility = 'visible';
            topbtn.style.visibility = 'visible';
        } else {
            // mailbtn.style.visibility = 'hidden';
            // whatsappbtn.style.visibility = 'hidden';
            topbtn.style.visibility = 'hidden';
        }
      };
 }
  
export default CustomBtnFunctionality
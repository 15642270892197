import "./download_app.css";
import DownloadAppImg from "../../assets/images/download_app/download_app_01.webp";

const DownloadApp = (props) => {
  return (
    <div className="container hero__container download_section">
      <div className="part-1 " data-aos="fade-right">
        <small></small>
        <h4>{props.title}</h4>
        <h2>{props.heading}</h2>
        <p>{props.desc_1}</p>
        <p>{props.desc}</p>
      </div>
      <div className="part-2 " data-aos="fade-right">
        <img src={DownloadAppImg} alt="Download Helper Adda" />
      </div>
    </div>
  );
};
export default DownloadApp;

import "./NotFoundMain.css"
import { Link } from 'react-router-dom'
import NotFound from "../../assets/images/notfound.webp"
const NotFoundMain = () => {
    return (
      <div className="container Notfound__container">
       <div className="notfountwrapper">
        <img src={NotFound} alt="Not Found" />
        <h1>404 - PAGE NOT FOUND</h1>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable</p>
        <Link to='/'><div className="GoTOHomeBtn">Go to Home Page</div></Link>
       </div>
      </div>
    )
  }
  
  export default NotFoundMain
import "./WorkProcess.css";
import DownloadApp from "../../assets/images/download_app.webp";
import BookService from "../../assets/images/book_service.webp";
import GetService from "../../assets/images/get_services.webp";
import { AiOutlineSetting } from "react-icons/ai";

// import React from "react";

const WorkProcess = () => {
  return (
    <div className="missionvision__container" data-aos="fade-right">
      <div className="container">
        <div className="MissionVisionValue">
          <div className="centre-heading">
            <div className="top-heading">
              <h5><AiOutlineSetting />  Our Working Process
              </h5>
            </div>
            <h2>Our Basic Work Process</h2>
          </div>
          {/* <div className="part-1 missionvision__content ">
            <span>Our Services</span>
            <h2>What services we offer !</h2>
          </div> */}
          <div className="MissionVisionValue_wrap">
            <div className="value">
              <img src={DownloadApp} alt="Download App" />
              <div className="value_inner_content">
                <h3>Download App</h3>
                <p>
                Unlock endless possibilities at your fingertips with our app, get extraordinary experiences
                </p>
              </div>
            </div>
            <div className="value">
              <img src={BookService} alt="Book Service" />
              <div className="vision_inner_content">
              <h3>Book Services</h3>
                <p>
                Browse, book, and enjoy a hassle-free way to access your household services.
                </p>
              </div>
            </div>

            <div className="value">
              <img src={GetService} alt="Get Service" />
              <div className="mission_inner_content">
              <h3>Get Services</h3>
                <p>
                With our user-friendly, one-stop app, you can easily and quickly get high-quality services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WorkProcess;

import React, { StrictMode } from "react";
import NotFoundMain from "../../ui/notfound_components/NotFoundMain";

const NotFound = () => {
  return (
    <StrictMode>
      <NotFoundMain/>
    </StrictMode>
  );
};

export default NotFound;

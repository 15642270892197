import "./LogoRegister.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DWSSCImg from "../../assets/images/register_img/img_01.webp";
import LaborImg from "../../assets/images/register_img/img_02.webp";
import MakeIndiaImg from "../../assets/images/register_img/img_03.webp";
import StartUpImg from "../../assets/images/register_img/img_04.webp";
import MSMEImg from "../../assets/images/register_img/img_05.webp";
import DigitalIndia from "../../assets/images/register_img/img_06.webp";
import React from "react";

export const LogoRegister = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="container register_section" data-aos="fade-right">
      <div className="heading">
        <h2>Registered With</h2>
      </div>
      <Carousel
        responsive={responsive}
        className="register_carousel"
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={1000}
      >
        <div className="card">
          <img src={DWSSCImg} alt="DWSSC" />
        </div>
        <div className="card">
          <img src={LaborImg} alt="MLE" />
        </div>
        <div className="card">
          <img src={MakeIndiaImg} alt="Make in India" />
        </div>
        <div className="card">
          <img src={StartUpImg} alt="Startup India" />
        </div>
        <div className="card">
          <img src={MSMEImg} alt="MSMI" />
        </div>
        <div className="card">
          <img src={DigitalIndia} alt="Digital India" />
        </div>
      </Carousel>
    </div>
  );
};
export default LogoRegister;

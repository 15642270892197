import "./services_components.css";
import { AiOutlineSetting } from "react-icons/ai";
import { ServicePagesSecSecond } from "../../data";
import { Link } from "react-router-dom";

const ServiceOffer = (props) => {
  return (
    <div className="container services_offer_section">
      <div className="services_heading content_section" data-aos="fade-right">
        <h5>
          <AiOutlineSetting /> {props.title}
        </h5>
        <h2>{props.heading}</h2>
      </div>
      <div className="services_section">
        {ServicePagesSecSecond.map(
          (
            {  SecTop, SecDesc, SecOneImg },
            service
          ) => {
            return (
              <div className="service_wrapper_card" data-aos="fade-right" key={service}>
                <h3>{SecTop}</h3>

                <div className="services_inner"  >
                  <div className="services_inner_content" >
                    <p>{SecDesc}</p> <br />
                    <div className="services_button">
                      <p><strong>For more details : </strong></p>
                      <Link to={"https://play.google.com/store/apps/details?id=com.helperaddda.app"} target="_blank" className="readMore_btn">Click Here</Link>
                    </div>
                  </div>
                  <img src={SecOneImg} alt={SecTop} />

                </div>


              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default ServiceOffer;

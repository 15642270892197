import { Link } from "react-router-dom";
import {DownloadLinks} from "../../data"
import Playstore from "../../assets/images/footer-playstore.webp";
import Appstore from "../../assets/images/footer-appstore.webp";
import HeroImg from "../../assets/images/hero-img.webp";
import './HomeHero.css'

const HomeHero = (props) => {
  const CommingSoon = () => {
    alert('Helper Adda is Comming soon on App Store');
  };

  return (
    <div className="container hero__container">
      <div className="part-1 " data-aos="fade-right">
        <small></small>
        <h4>{props.name}</h4>
        <h1><span style={{color:'var(--primary)'}}>Change your Life,</span> Hire your Domestic Help in minutes !</h1>
        <p>{props.desc}</p>
        <div className="download__store">
          <Link to={DownloadLinks[0].url} className="playstore_logo" target="_blank">
            <img src={Playstore} alt={DownloadLinks[0].name} />
          </Link>
          <Link onClick={CommingSoon} className="ios_logo">
            <img src={Appstore} alt={DownloadLinks[1].name} />
          </Link>
        </div>
      </div>
      <div className="part-2" data-aos="fade-left">
        <img src={HeroImg} alt="Helper Adda" />
      </div>
    </div>
  )
}
export default HomeHero;
import "./why_choose_us_components.css";
import MainImg from "../../assets/images/services_img/img_01.webp";
import MainImg_01 from "../../assets/images/services_img/inner-img_01.webp";
import MainImg_02 from "../../assets/images/services_img/inner-img_02.webp";
import MainImg_03 from "../../assets/images/services_img/inner-img_03.webp";

const WhyChooseUs = (props) => {
  return (
    <div className="container choose_section">
      <div className="content_section title" data-aos="fade-right">
          <small></small>
          <span>{props.title}</span>
          <h2>
            <strong>Helper Adda,<br /> </strong>
            Get Services At Affordable Price
          </h2>
          </div>
      <div className="about_main_section ">
        
          <div>
          <div className="main_inner_section" data-aos="fade-right">
            <img src={MainImg_01} alt="Why Choose Helper Adda" />
            <div className="main_inner_content">
              <h4>More Reliable & Experienced Teams</h4>
              <p>
              Years of expertise serve as the north star, Even the most difficult situations can be successfully handled by teams.
              </p>
            </div>
          </div>
          <div className="main_inner_section" data-aos="fade-right">
            <img src={MainImg_02} alt="Why Choose Helper Adda" />
            <div className="main_inner_content">
              <h4>Getting Services at Affordable price</h4>
              <p>
              Affordable services enable individuals and organizations to thrive by filling the gap between financial restraints and basic requirements.
              </p>
            </div>
          </div>
          <div className="main_inner_section" data-aos="fade-right">
            <img src={MainImg_03} alt="Why Choose Helper Adda" />
            <div className="main_inner_content">
              <h4>More Reliable & Experienced Teams</h4>
              <p>
              Experience is the compass that guides reliability, ensuring teams can navigate even the most challenging situations with confidence.
              </p>
            </div>
          </div>
        </div>
        <div className="img_section" data-aos="fade-right">
          <img src={MainImg} alt="Why Choose Helper Adda" />
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;

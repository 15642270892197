import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../../assets/images/logo.svg'
import { links } from '../../data.js'
import { CgMenuLeft } from 'react-icons/cg'
import { AiFillCloseCircle } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
import './navbar.css'

const Navbar = () => {
  const [isNavShowing, setisNavShowing] = useState(false);

  const NavigateClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <nav>
      <div className="container nav__container">
        <Link to="/" className='logo' onClick={() => {setisNavShowing(false); NavigateClick()}}>
          <img src={Logo} alt="Helper Adda Logo" />
        </Link>
        <ul className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
          {
            links.map(({ name, path }, index) => {
              return (
                <li key={index}>
                  <NavLink to={path} className={({ isActive }) => isActive ? 'active-nav' : ''}
                    onClick={() => {setisNavShowing(prev => !prev); NavigateClick()}}>{name}</NavLink>
                </li>
              )
            })
          }
          <Link to="/download" onClick={() => {setisNavShowing(false); NavigateClick()}}><button className='btn-1 '>Download App</button></Link>
          <Link to="https://youtube.com/playlist?list=PLXhi6XVkUhHX-SAR1sWAc8Sbm25dwX5aS&si=R_FXqdaXvkLhpICQ" target="_blank" onClick={() => {setisNavShowing(false); NavigateClick()}}><button className='TraningModuleBtn'>Training Module <AiFillYoutube/></button></Link>
        </ul>
        <button className='nav__toggle-btn' onClick={() => setisNavShowing(prev => !prev)}>
          {
            isNavShowing ? <AiFillCloseCircle /> : <CgMenuLeft />
          }
        </button>
      </div>
    </nav>
  )
}

export default Navbar
import './Policies.css'
export const TermsConditions = () => {
    return (
        <div className="container Privacy_policy_Container">
            <div className="centre-heading mb-3">
                <div className="top-heading">
                    <h5>Terms & Conditions</h5>
                </div>
                <h1>Our Terms & Conditions for a Seamless Experience</h1>
            </div>
            <div className="content__wrapper">
                <p>These terms and conditions ("Terms") govern the use of services made available on or through Shailasia Management Pvt Ltd [Helper Adda] and/or the 'Helper Adda' mobile app (collectively, the "Platform", and together with the services made available on or through the Platform, the "Services"). These Terms also include our privacy policy, available at [ Privacy Policy ] ("Privacy Policy"), and any guidelines, additional, or supplemental terms, policies, and disclaimers made available or issued by us from time to time ("Supplemental Terms"). The Privacy Policy and the Supplemental Terms are integral to these Terms. In the event of a conflict between these Terms and the Supplemental Terms concerning applicable Services, the Supplemental Terms will prevail.</p><br />
                <p>The Terms constitute a binding and enforceable legal contract between Shailasia Management Private Limited (a company incorporated under the Companies Act, 2013 with its registered address at 500 5th Floor, ITL Tower, Netaji Subhash Place,  Pitampura, New Delhi 110034 and its affiliates ("Helper Adda", "we", "us", or "our"), and you, a user of the Services, or any legal entity that books the Services (as defined below) on behalf of end-users ("you" or "User"). By using the Services, you represent and warrant that you have full legal capacity and authority to agree to and bind yourself to these Terms. If you represent any other person, you confirm and represent that you have the necessary power and authority to bind a such person to these Terms.</p><br />
                <p>By using the Services, you agree that you have read, understood, and are bound by these Terms, as amended from time to time, and will comply with the requirements listed here. These Terms expressly supersede any prior written agreements with you. If you disagree with these Terms or comply with the requirements, please do not use the Services.</p><br />
                <h6>1. Services</h6>
                <p>(a) The services include the provision of a platform that enables you to arrange and schedule different home-based services with independent third-party services providers of those services ("Service Professionals"). As a part of the services, Helper Adda facilitates the transfer of payment to service professionals for the services they render to you and collects payments on behalf of such service professionals.</p><br />
                <p>(b) An integral part of the Services is Helper Adda's ability to send you messages (including on text, WhatsApp, through the application/platform of Helper Adda) and electronic mail for various reasons, including in connection with your bookings / your utilization of the Services, or as a part of, promotional, marketing and other commercial strategies of Helper Adda.</p><br />
                <p>(c) In certain instances, you may be required to furnish identification proof to avail of the Services and, with this agreement, to do so. Failure to comply with this request may result in your inability to use the Services.</p><br />

                <h6>2. Account Creation</h6>
                <p>(a) To avail of the Services, you will be required to create an account on the Platform ("Account"). For this Account, you may be required to furnish specific details, including but not limited to your phone number. To create an Account, you must be at least 18 years of age.</p><br />
                <p>(b) You warrant that all information furnished with your Account is and shall remain accurate and true. You agree to promptly update your details on the Platform in case of any change to or modification of this information.</p><br />
                <p>(c) You are solely responsible for maintaining the security and confidentiality of your Account and agree to immediately notify us of any disclosure or unauthorized use of your Account or any other breach of security concerning your Account.</p><br />
                <p>(d) You are liable and accountable for all activities through your Account, including activities performed by persons other than you. We shall not be liable for any unauthorized access to your Account.</p><br />
                <p>(e) You agree to receive communications from us regarding (i) requests for payments, (ii) information about the Services and us, (iii) promotional offers and services from our third-party partners and us, and (iv) any other matter concerning the Services</p><br />

                <h6>3. User Content</h6>
                <p>(a) Our Platform may contain interactive features or services that allow Users who have created an account with us to post, upload, publish, display, transmit, or submit comments, reviews, suggestions, feedback, or other content on or through the Platform ("User Content").</p><br />
                <p>(b) As part of the effective provision of the Services and quality control purposes, we may request reviews from you about service professionals and you agree and acknowledge that service professionals may provide reviews about you to us. You must not knowingly provide false, inaccurate, or misleading information in respect of the reviews. Reviews may, among other purposes, be used by us for quality control purposes and to determine whether Users/Customers and Services are appropriate users of the Platform. If we determine at our sole discretion that you are not an appropriate user, we reserve the right to cancel your registration and remove you from our Platform.</p><br />
                <p>(c) You grant us a non-exclusive, worldwide, perpetual, irrevocable, transferable, sublicensable, and royalty-free license to (i) use, publish, display, store, host, transfer, process, communicate, distribute, make available, modify, adapt, translate, and create derivative works of, the User Content, for the functioning of, and in connection with, the Services and (ii) use User Content for the limited purposes of advertising and promoting the Services, or furnishing evidence before a court or authority of competent jurisdiction under applicable laws.</p><br />
                <p>(d) In connection with these Terms and the licenses granted under this clause, you hereby waive any claims arising out of any moral rights or other similar rights relating to the User Content.</p><br />
                <p>(e) You agree and acknowledge that Helper Adda may, without notice to you, remove, or otherwise restrict access to User Content that, in its sole discretion, violates these Terms.</p><br />

                <h6>4. Consent to use Data</h6>
                <p>(a) You agree that we may, in accordance with our Privacy Policy, collect and use your personal data. The Privacy Policy is available at [ Privacy Policy ] and it explains the categories of personal data that we collect or otherwise process about you and the manner in which we process such data.</p><br />
                <p>(b) In addition to any consent you may provide pursuant to the Privacy Policy, you hereby consent to us sharing your information with our affiliates or other third parties. We may use information and data pertaining to your use of the Services for various purposes including but not limited to the provision of the Services, analytics, trend identification, targeted marketing, and purposes of statistics to further enhance the effectiveness and efficiency of our Services, and provision of beneficial schemes, new offers, offers on third party products and services, and for experience enhancement.</p><br />
                <p>(c) Subject to applicable laws, we may be directed by law enforcement agencies or the government and related bodies to disclose data in relation to you in connection with criminal or civil proceedings. You understand and agree that in such instances we shall have the right to share such data with relevant agencies or bodies.</p><br />

                <h6>5. Bookings</h6>
                <p><strong>(a) Orders: </strong> The Platform permits you to request Services at a time of your choosing based on available slots. To make a booking, you should follow the instructions on the Platform and provide the necessary information. We use reasonable efforts to enable you to find a Service Professional who can provide that service at the requested time. If, in the unlikely event, we are still looking for a Service Professional for the specific time slot, we will contact you to find an alternative time.</p><br />
                <p><strong>(b) Confirmation: </strong> Once you place a request, we will confirm the booking via a message (such as WhatsApp/SMS / in-app notification), email or push notification. Once your booking has been confirmed, you will be required to make the payment in accordance with these Terms or as indicated on the Platform. Once a Service professional has been identified for the requested Services, you shall receive confirmation in the App or via a message (such as WhatsApp/SMS/in-app notification), email or push notification.</p><br />
                <p><strong>(c) Cancellations: </strong> Bookings cancelled before confirmation on the Platform will not be charged. Helper Adda's cancellation policy sets out applicable cancellation fees.</p><br />
                <p><strong>(d) Substitution: </strong> In case of the unavailability of, or cancellation by a selected Service Professional, we will offer you a substitute of the Service Professional from among our registered Services Professionals.</p><br />

                <h6>6. Pricing, Fees and Payment Terms</h6>
                <p>(a) Helper Adda reserves the right to charge you for the different Services you may avail and/or for any other facilities you may opt for, from time to time on or via the Platform.</p><br />
                <p><strong>(b) Charges and Fees in respect of Services: </strong></p><br />
                <p>(i) In respect of Services that you seek to avail through the Platform, the amount indicated at the time of booking as well as the amounts towards (a) any additional Services you may avail and (b) out-of-pocket expenses incurred by the Service Professional, are termed as "Charges". In addition to the Charges payable to Service Professional, Service Professional reserves the right to charge you a convenience fee for facilitating the booking and transferring payments to the Services (this fee is referred to as "Fees"). You acknowledge that the final bill you receive may include additional charges, including, without limitation, a safety fee, warranty fee, insurance fee, or Service welfare fee.</p><br />
                <p>(ii) Helper Adda shall notify you of the applicable Charges, Fees, and payment methods at the time of booking. Generally, you may make payments for Services through credit cards, debit cards, net banking, wallets, or UPI upon booking. We have the right to modify and otherwise restrict the modes of payment available to you. We do not accept payments via cash for any bookings, and if you provide any cash/advances/loans etc. to a service, Helper Adda shall not be responsible for any loss suffered by you.</p><br />
                <p>(iii) The Charges and Fees are payable when the Services are completed, as Helper Adda specified.</p><br />
                <p>(iv) For the avoidance of doubt, please note that the Charges are payable to Service Professionals, and Helper Adda acts as a limited collection agent on behalf of such Service Professionals to collect and transfer amounts due to them.</p><br />
                <p><strong>(v) Taxes:</strong> All Charges and Fees are inclusive of applicable taxes.</p><br />
                <p>(vi) Helper Adda reserves the right to reasonably amend the Charges and Fees at any time at its sole discretion. A change in Fees shall not impact any bookings that have been confirmed before the publication of the revised Fees on the Platform.</p><br />
                <p>(vii) Charges and Fees that you pay are final and non-refundable unless otherwise determined by Helper Adda or required by applicable laws. Under specific laws, you may be entitled to a refund or other remedies for a failure in the provision of the Services.</p><br />
                <p>(viii) You acknowledge and agree that Charges and Fees applicable in certain geographical areas may increase substantially during times / time slots of high demand. Helper Adda will use reasonable efforts to inform you of the Charges and Fees that may apply. However, by using the Services­, you will be responsible for the Charges and Fees incurred under your Account regardless of your awareness of such Charges or Fees.</p><br />
                <p><strong>(c) Payment Processors: </strong> We may use a third-party payment processor ("Payment Processor") to bill you through your selected mode of payment. The processing of payments will be subject to the terms and policies of such Payment Processors in addition to these Terms. We shall not be liable for any error of the Payment Processor. In the event of any unsuccessful payment, the money debited shall be credited in accordance with the terms of the Payment Processor.</p><br />
                <p><strong>(d) Cancellation: </strong> You may elect to cancel your request for services from a Service Professional at any time prior to such Service Professionals' arrival, in which case you may be charged a cancellation fee in accordance with Helper Adda's cancellation policy. Helper Adda reserves the right to charge you or otherwise deduct applicable taxes regarding such cancellation fee.</p><br />
                <p>(e) Helper Adda does not designate any portion of your payment as a tip or gratuity to the Service Professional. Any representation by Helper Adda to the effect that tipping is "voluntary", "not required", and/or "included" in the payments you make for Services is not intended to suggest that Helper Adda provides any additional payments to Service Professionals. You understand and agree that while you are free to provide additional payment as a gratuity to any Service Professional who provides you with Services, you are under no obligation to do so. Gratuities are voluntary.</p><br />

                <h6>7. Replacement & Refund Policy</h6>
                <p>These are the terms and conditions for refunds and replacements for domestic help/cook/caretaker/event planner/24x7 buddy help or any other services obtained from Helper Adda.</p>
                <p>If the service professional is not skilled, is unwilling to work and has behavioural issues, or has medical conditions that cause an issue with the work or could be a concern for the customer or their family members, the customer will be given a replacement.</p>
                <p>Once the service is completed, the customer must pay online, so there will be no refund. If you are dissatisfied with the service, please notify our customer service immediately so that we can replace the service professional.</p>

                <h6>8. User and Customer Conduct</h6>
                <p>(a) Helper Adda prohibits discrimination against Service Professionals, including based on race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age, or any other characteristic that may be protected under applicable law. Such discrimination includes but is not limited to any refusal to accept Services based on any of these characteristics.</p>
                <p>(b) We request that you treat all Service Professionals with courtesy and respect and that you provide them with a safe, clean, and appropriate location to perform the Services. Service Professionals shall be entitled to refuse to perform Services if you have not provided a safe, clean, and appropriate location for them or if you behave towards them in a manner that is discourteous, disrespectful, abusive, or otherwise inappropriate. We reserve the right to withhold access to the Services and otherwise limit your access to Services at our absolute discretion, for any purpose whatsoever, including if you behave towards any Service Professional in a manner that is discourteous, disrespectful, or abusive, or which we otherwise deem to be inappropriate or unlawful.</p>
                <p>(c) You agree that you will be liable for discriminating against Service Professionals or for any failure, intentional or otherwise, to provide the Service Professionals with a safe, clean, and appropriate location for them to perform the Services. Additionally, you will also disclose any information that may have a bearing on the ability of the Service Professionals to perform the Services or impact the Services Professional's health, safety, or well-being, to Helper Adda and the Service Professional.</p>
                <p>(d) You agree that in the event a Service Professional behaves in a manner that is discourteous, disrespectful, abusive, inappropriate, or in violation of the law, you shall be required to report such incident to support@helperadda.com at the earliest but in any event within 48 (forty-eight) hours of such incident.</p>

                <h6>9. Refer & Earn</h6>
                <p>(a) To refer someone to a service, the user must have received at least one Helper Adda service.</p>
                <p>(b) Credits earned through referrals can only be redeemed as a discount on services purchased through the Helper Adda app or website.</p>
                <p>(c) If the person being referred is an existing Helper Adda app or website user and has already used at least one service, neither the person being referred nor the person referring will receive referral credits.</p>
                <p>(d) Only the first link received in chronological order will be validated if a user receives multiple referral links.</p>
                <p>(e) Helper Adda reserves the right to assign various reward values to different users and service categories.</p>
                <p>(f) Helper Adda reserves the right to declare any reward null and void at any time.</p>
                <p>(g) Helper Adda reserves the right to block the usage of rewards or credits for certain users at its own discretion.</p>
                <p>(h) Helper Adda reserves the right to modify the nature of the referral programme and implement new rewards and discounts for the sender and/or receiver.</p>
                <p>(i) All programme rewards are strictly non-transferable and must be used by the same user on the same device ID.</p>
                <p>(j) You can only claim referral rewards for up to ten new users.</p>

                <h6>10. Third Party Sercices</h6>
                <p>(a) The Platform may include services, content, documents, and information owned by, licensed to, or otherwise made available by, a third party ("Third Party Services") and contain links to Third Party Services. You understand and acknowledge that Third Party Services are the sole responsibility of the third party that created or provided them and that use of such Third Party Services is solely at your own risk.</p>
                <p>(b) We make no representations and exclude all warranties and liabilities arising out of or pertaining to such Third Party Services, including their accuracy or completeness. Should you avail/purchase a Third Party Service, you shall be governed and bound by the terms and conditions and privacy policy of the third parties providing the Third Party Services. Further, all intellectual property rights in and to Third Party Services are the property of the respective third parties.</p>

                <h6>11. Yours Responsibilties</h6>
                <p>(a) You represent and warrant that all information that you provide in relation to the Services is complete, true, and correct on the date of agreeing to these Terms and shall continue to be complete, true, and correct while you avail the Services. Should any information that you provide change during the existence of these Terms, you undertake to immediately bring such change to our notice. We do not accept any responsibility or liability for any loss or damage that you may suffer or incur if any information, documentation, material, or data, provided to avail the Services is incorrect, incomplete, inaccurate, or misleading or if you fail to disclose any material fact.</p>
                <p>(b) You shall extend all cooperation to us in our defense of any proceedings that may be initiated against us due to a breach of your obligations or covenants under these Terms.</p>
                <p>(c) In respect of the User Content, you represent and warrant that:</p> <br />

                <p>(I)you own all intellectual property rights (or have obtained all necessary permissions) to provide User Content and to grant the licenses under these Terms;</p>
                <p>(II)you are solely responsible for all activities that occur on or through your Account on the Platform and all User Content;</p>
                <p>(III)the User Content does not and shall not violate any of your obligations or responsibilities under other agreements;</p>
                <p>(IV)the User Content does not and shall not violate, infringe, or misappropriate any intellectual property right or other proprietary rights, including the right of publicity or privacy of any person or entity;</p>
                <p>(V)the User Content does not and shall not contain any viruses, corrupted data, or other harmful, disruptive, or destructive files or content;</p>
                <p>(VI)the User Content does not and shall not 'violate any third-party rights; and</p>
                <p>(VII)the User Content (A) does not belong to any other person to which you do not have any right, (B) does not threaten the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, public order, cause incitement to the commission of any cognizable offense, prevents investigation of any offense, or is insulting another nation, (C) is not defamatory, grossly harmful, profane, paedophilic, invasive of another's privacy, discriminatory based on gender, ethnically objectionable, disparaging, relating to, or encouraging money laundering or gambling, libellous, hateful, racist, violent, obscene, pornographic, unlawful, harmful to children, or (D) otherwise offensive, objectionable, or restricts, or inhibits, any other person from using or enjoying the Services. (d) You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting the generality of the preceding sentence, you shall not:</p><br />

                <p>(i) infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of any party;</p>
                <p>(ii) except as may be provided hereunder, copy, display, distribute, modify, publish, reproduce, store, transmit, post, translate, create any derivative works from or license the Services;</p>
                <p>(iii) use the Services to transmit any data or send or upload any material that contains viruses, Trojan horses, worms, timebombs, keystroke loggers, spyware, adware, or any other harmful programs, or similar computer code, designed to adversely affect the operation of any computer software or hardware;</p>
                <p>(iv) use any robot, spider, other automated devices, or manual process to monitor or copy the Services or any portion thereof;</p>
                <p>(v) engage in the systematic retrieval of content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory;</p>
                <p>(vi) use the Services in (A) any unlawful manner, (B) for fraudulent or malicious activities, or (C) in any manner inconsistent with these Terms;</p>
                <p>(vii) decompile, reverse engineer, or disassemble the Services;</p>
                <p>(viii) link to, mirror, or frame, any portion of all or any of the Services; or.</p>
                <p>(ix) violate applicable laws in any manner.</p><br />

                <p>(e) You warrant that you shall not engage in any activity that interferes with or disrupts the Services.</p> <br />
                <p>(f) You shall not attempt to gain unauthorized access to any portion or feature of the Services, any other systems or networks connected to the Services, to any of our servers, or through the Platform by hacking, password mining, or any other illegitimate means.</p> <br />
                <p>(g) You shall not directly or indirectly, in any capacity, solicit, attempt to influence, engage, approach, or accept or encourage the solicitations or approach of, a Service Professional from whom you have availed Services, to either terminate or otherwise cease their registration on or engagement with the Platform, or avail services the same as or similar to the Services independently, without booking the Services through your Account. You agree that this limitation is reasonable and fair and is necessary for the protection of the privacy and security of Service Professionals and that this will not preclude you from obtaining services the same as or similar to the Services through the Platform or other means. You further agree that any potential harm to Service Professionals from the non-enforcement of this clause far outweighs any potential harm to you.</p> <br />

                <h6>12. DND/NDNC Policy</h6>
                <p>By using the Platform, you authorize us to contact you via email or phone call, or sms and offer you our Services, offer promotional offers running on the website & offers offered by the associated third parties, for which reasons, personally identifiable information may be collected. And irrespective of the fact that you have also registered yourself under DND or DNC or NCPR service, you still authorize us to give you a call from Helper Adda for the purposes mentioned above till 365 days after your registration with us.</p>

                <h6>13. Our Intellectual Property</h6>
                <p>(a) All rights, titles, and interest in, and to the Services, including all intellectual property rights arising out of the Services, are owned by or otherwise licensed to us. Subject to compliance with these Terms, we grant you a non-exclusive, non-transferable, non-sublicensable, revocable, and limited license to use the Services in accordance with these Terms and our written instructions issued from time to time. Any rights not expressly granted herein are reserved by Helper Adda or Helper Adda's licensors.</p>
                <p>(b) We may request you to submit suggestions and other feedback, including bug reports, relating to the Services from time to time ("Feedback"). We may freely use, copy, disclose, publish, display, distribute, and exploit the Feedback we receive from you without any payment of royalty, acknowledgement, prior consent, or any other form of restriction arising out of your intellectual property rights.</p>
                <p>(c) Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to our or any third party's intellectual property rights.</p>

                <h6>14. Term & Termination</h6>
                <p>(a) These Terms shall remain in effect unless terminated in accordance with the terms hereunder.</p>
                <p>(b) We may restrict, deactivate, or terminate your access to, or use of, the Services, or any portion thereof, (i) immediately and at any point at our sole discretion, (A) if you violate or breach any of the obligations, responsibilities, or covenants under these Terms, (B) when you cease to become a user of our Services, or (C) you do not, or are likely not to qualify under applicable law, or the standards and policies of Helper Adda or its affiliates, to access and use the Services; (ii) upon 30 (Thirty) days prior written notice to you; or (iii) immediately for any other legitimate business (in the sole discretion of Helper Adda), legal, or regulatory reason.</p>
                <p>(c) You may terminate these Terms at any time, for any reason by sending a notice to Helper Adda at support@helperadda.com</p>
                <p>(d) Upon termination of these Terms:</p> <br />
                <p>(i) The Account will expire;</p>
                <p>(ii) The Services will "time-out"; and</p>
                <p> (iii) These Terms shall terminate, except for those clauses that are expressly, or by implication, intended to survive termination or expiry.</p>

                <h6>15. Disclaimers and Warranties</h6>
                <p>(a) The Services are provided on an "as is" basis without warranty of any kind, express, implied, statutory or otherwise, including without limitation the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, we make no warranty that the Services will meet your requirements or expectations.</p>
                <p>(b) No advice or information, whether oral or written, obtained by you from us shall create any warranty that is not expressly stated in the Terms.</p>
                <p>(c) While Helper Adda strives to provide accurate information about Services and Charges, pricing errors may occur from time to time.</p>
                <p>(d) You agree and acknowledge that we are merely a Platform that connects you with Service Professionals, and we shall not be liable in any manner for any obligations that have not been explicitly stated in these Terms. We are not liable or responsible for fulfilment of any bookings, for the performance of the Services by any Service Professional, or for any acts or omissions of the Services during their provision of the Services including any damage they may cause to property. By booking Services through the Platform, you are entering into a contract with the relevant Service Provider for the provision of those services, and we accept no responsibility or liability, nor do we make any warranty, representation, or guarantee in respect of the Service Professional's performance under that contract.</p>
                <p>(e) You agree and acknowledge that soliciting or receiving services from any Service Professional independently is solely at your own risk, and in such an event, you waive any rights that you may have under these Terms.</p>
                <p>(f) We do not guarantee or warrant and we make no representation whatsoever regarding the reliability, quality, or suitability of the Service Professionals.</p>
                <p>(g) You hereby accept full responsibility for any consequences that may arise from your use of the Services, and expressly agree and acknowledge that we shall have absolutely no liability in this regard.</p>
                <p>(h) Helper Adda maintains a complaints management framework and will try to manage this framework on behalf of Service Professionals in a reasonable manner and in accordance with the non-excludable requirements of relevant applicable laws.</p>
                <p>(i) To the fullest extent permissible by law, we, our affiliates, and our related parties, each disclaim all liability for any loss or damage arising out of, or due to:</p> <br />
                <p>(i)your use of, inability to use, or availability or unavailability of the Services; (ii)the occurrence or existence of any defect, interruption, or delays, in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction, or unauthorized access to our records, programs, services, servers, or other infrastructure relating to the Services; (iii)the failure of the Services to remain operational for any period of time: and (iv)the loss of any User Content and any other data in connection with your use of the Services.</p><br />

                <p>(j) In no event shall Helper Adda, its officers, directors, and employees, or its contractors, agents, licensors, partners, or suppliers, be liable to you for any direct, special, indirect, incidental, consequential, punitive, reliance, or exemplary damages (including without limitation, lost business opportunities, lost revenues, or loss of anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever, including but not limited to any abuse or breach of data), even if Helper Adda or an authorized representative had been advised of the possibility of such damages, arising out of, or relating to (A) these Terms, (B) the Services, (C) your use or inability to use the Services, or (D) any other interactions with another user of the Services.</p> <br />
                <p>(k) To the maximum extent permitted by law, our liability shall be limited to the amount of commission we receive in respect of a particular booking made on the Platform. In no event shall our total liability to you in connection with these Terms exceed INR 5,000 (Rupees Five Thousand).</p> <br />
                <p>(l) Nothing in these Terms will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit.</p> <br />

                <h6>16. Indemnity</h6>
                <p>You shall indemnify, defend at our option, and hold us, our parent companies, subsidiaries, affiliates, and our officers, employees, directors, agents, and representatives harmless from and against any claim, demand, lawsuits, a judicial proceeding, losses, liabilities, damages, and costs (including, without limitation, all damages, liabilities, settlements, and attorneys' fees), due to or arising out of your access to the Services, use of the Services, violation of these Terms, or any violation of these Terms by any third party who may use your Account.</p>

                <h6>17. Jurisdiction, Governing Laws & Dispute Resolution</h6>
                <p>(a) These Terms shall be governed by and construed and enforced in accordance with the laws of India. Subject to other provisions in this clause, courts in Uttar Pradesh shall have exclusive jurisdiction over all issues arising out of these Terms or the use of the Services.</p>
                <p>(b) Any controversies, conflicts, disputes, or differences, arising out of these Terms shall be resolved by arbitration in Nodia, UP in accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, which is deemed to be incorporated by reference in this clause. The tribunal shall consist of 1 (one) arbitrator mutually appointed by Helper Adda. The language of the arbitration shall be English. The parties to the arbitration shall keep the arbitration confidential, and not disclose to any person, other than on a need-to-know basis, or to legal advisors unless required to do so by law. The decision of the arbitrator shall be final and binding on all the parties thereto. Each party to the arbitration shall bear its own costs with respect to any dispute.</p>

                <h6>Grievance Redressal</h6><br />
                <p><strong>(a) </strong> You may contact our designated Grievance Redressal Officer with any complaints or queries relating to the Services or these Terms and conditions:</p>
                <p><strong>Name :</strong> Munmun Singh</p>
                <p><strong>Designation :</strong> Founder</p>
                <p><strong>Operations Email Address :</strong> support@helperadda.com</p> <br />

                <p><strong>(b) </strong> We shall ensure that your complaint is resolved within the timelines advised by applicable laws.</p>

                <h6>19. Miscellaneous Provisions</h6>
                <p><strong>(a) Changes to Terms : </strong>The Terms are subject to revisions at any time, as determined by us, and all changes are effective immediately upon being posted on the Platform. It is your responsibility to review these Terms periodically for any updates or changes. You will be deemed to have accepted the changes made to these Terms if you continue to use the Platform once it has been posted.</p>
                <p><strong>(b) Modification to the Services: </strong>We reserve the right at any time to add, modify, or discontinue, temporarily or permanently, the Services (or any part thereof), with or without cause. We shall not be liable for any such addition, modification, suspension, or discontinuation of the Services.</p>
                <p><strong>(c) Severability: </strong> If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue to be in effect. If any unlawful or unenforceable provision would be lawful or enforceable if a part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).</p>
                <p><strong>(d) Assignment: </strong>You shall not license, sell, transfer, or assign your rights, obligations, or covenants under these Terms, or your Account in any manner without our prior written consent. We may grant or withhold this consent at our sole discretion, subject to any conditions we deem appropriate. We may assign our rights to any of our affiliates, subsidiaries, or parent companies, any successor in interest of any business associated with the Services, or any third party without any prior notice to you.</p>
                <p><strong>(e) Notices: </strong>All notices, requests, demands, and determinations for us under these Terms (other than routine operational communications) shall be sent to support@helperadda.com.</p>
                <p><strong>(f) Third Party Rights: </strong>No third party shall have any rights to enforce any terms contained herein.</p>
                <p><strong>(g) Force Majeure:  </strong>We shall have no liability to you if we are prevented from or delayed in performing our obligations, or from carrying on our business, by acts, events, omissions, or accidents beyond our reasonable control, including without limitation, strikes, failure of a utility service or telecommunications network, act of God, war, riot, civil commotion, malicious damage, or compliance 'with any law or governmental order, rule, regulation, or direction.</p>
            </div>

        </div>
    )
}

export default TermsConditions
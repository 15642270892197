import React, { StrictMode } from "react";
import AboutMain from "../../ui/about_components/about_main_components";
import HomeAbout from "../../ui/home_components/HomeAbout";
import WhyChooseUs from "../../ui/services_components/why_choose_us_components";
import MetaLogic from "../../metalogic"
// import OurCustomer from "../../ui/about_components/our_customer_components";
// import TopUp from "../../components/TopUp/TopUp";

const About = () => {
  return (
    <StrictMode>
      <MetaLogic title={"Helper Adda - Real Time Domestic Helper"} description={"Find affordable, real-time domestic services that fit your needs. Our experienced professionals can help with everything from cleaning to childcare. Book now!"} canonicalUrl={"https://helperadda.com/about"} />
      <AboutMain
        title="Helper Adda"
        heading=""
        desc_1="Helper Adda is a one-stop solution for all your household needs from where you can hire professional & trained domestic helpers, including maids, babysitters, massagers, drivers, buddies 24*7, nurses, cleaners, pregnancy care, event planners and so on, for your assistance in a few minutes that is easy, quick and reliable."
        desc_2=""
      />
      <HomeAbout
        title="About our Company"
        name="Helper Adda"
        desc_1="Helper adda is powered by Shailasia Management Pvt Ltd, Our Aim is to reduce unemployment by providing jobs to needful individuals. We provide unique experiences with our workers and customers."
      />
      <WhyChooseUs title="Why Choose Us"/>
      {/* <OurCustomer title="Our Customers" next="View Next" /> */}
      {/* <TopUp/> */}
    </StrictMode>
  );
};

export default About;

import './Policies.css'
export const RefundPolicy = () => {
    return (
        <div className="container Privacy_policy_Container">
            <div className="centre-heading mb-3">
                <div className="top-heading">
                    <h5>Refund Policy</h5>
                </div>
                <h1>Our Refund Policy Reflects Our Commitment</h1>
            </div>
            <div className="content__wrapper">
                <h6>Thanks for using our service at Helper Adda.</h6>
                <p>These are the refunds and replacements for domestic help for cook, caretaker, event planner, 24x7 buddy, etc. These help or any other services obtained from Helper Adda.</p><br />
                <p>If the service professional is not skilled, is unwilling to work and has behavioral issues, or has medical conditions that cause an issue with the work or could be a concern for the customer or their family members, the customer will be given a replacement.</p><br />
                <p>Once the service is completed, the customer must pay online, so there will be no refund. If you are dissatisfied with the service, please notify our customer service immediately so that we can replace the service professional.</p>
            </div>

        </div>
    )
}

export default RefundPolicy
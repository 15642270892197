import "./BackedBy.css";
import AstLogo from "../../assets/images/register_img/avtar_space_logo.webp";
import ShailLogo from "../../assets/images/register_img/shail_international_logo.webp";
import TarParkingLogo from "../../assets/images/register_img/tar_parking_logo (1).webp";
import TokenLogo from "../../assets/images/register_img/token_sayaai_logo.webp";

const BackedBy = () => {
  return (
    <div className="container backend_section" data-aos="fade-right">
      <div className="heading">
        <h3>Backed By</h3>
      </div>
      <div className="logo_section">
        <div className="logo_img">
            <img src={AstLogo} alt="Avtar Space Technology"/>
        </div>
        <div className="logo_img">
            <img src={ShailLogo} alt="Shail International Group"/>
        </div>
        <div className="logo_img">
            <img src={TarParkingLogo} alt="Tar Parking"/>
        </div>
        <div className="logo_img">
            <img src={TokenLogo} alt="Token Sayari"/>
        </div>
      </div>
    </div>
  );
};
export default BackedBy;

import "./about_main_components.css"
import AboutMainImg from "../../assets/images/about_img/main_img_01.webp";
const AboutMain = (props) => {
  return (
    <div className="container hero__container">
      <div className="part-1" data-aos="fade-right">
        <small></small>
        <h4>{props.title}</h4>
        <h2><span style={{color:'var(--primary)'}}>Change your Life,</span> Hire your Domestic Help in minutes!</h2>
        <p>{props.desc_1}</p>
        <p>{props.desc_2}</p>               
      </div>
      <div className="part-2 about_img " data-aos="fade-right">
        <img src={AboutMainImg} alt="Helper Adda" />
      </div>
    </div>
  );
};
export default AboutMain;

import {TbArrowBigUpLinesFilled} from 'react-icons/tb'

const TopUp = () => {
  const TopToUpClick = () => {
    window.scrollTo(0, 0);
  };
    return (
      <div className="TopToUpBtn" id='TopToUpBtn' onClick={() => TopToUpClick()} data-aos="fade-up"><TbArrowBigUpLinesFilled/></div>
    )
  }
  
  export default TopUp;
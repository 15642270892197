// import './services.css'
import { StrictMode } from "react";

//===== Components=====
import ServiceOffer from "../../ui/services_components/services_components";
import UpcomingSerivces from "../../ui/services_components/upcomming_components";
// import {ServicePagesSecSecond} from '../../data';
import MetaLogic from '../../metalogic'

const Services = () => {
  return (
    <StrictMode>
      <MetaLogic title={"Helper Adda: Your One-Stop Shop for All Your Domestic Help"} description={" In Helper adda, We provide a variety of services, including nannies, maids, cooks, event planners, nurses, massagers & many more."} canonicalUrl={"https://helperadda.com/services"} />
      <ServiceOffer title="Our Active Services" heading="What services we offer ?"/>
      <UpcomingSerivces title="Upcomming Services" heading="We are Coming with new services"/>
    </StrictMode>
  );
};

export default Services;

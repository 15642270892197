import "./form_section.css";
import ContactImg from "../../assets/images/ContactImg.webp";
import { ContactDetails } from '../../data.js'
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import Location from "../../assets/icons/location.svg"
import Call from "../../assets/icons/call.svg"
import Emailicon from "../../assets/icons/email.svg"
import { Link } from "react-router-dom";

const ContactFormSection = () => {
  const NavigateClick = () => {
    window.scrollTo(0, 0);
  };

  const form = useRef();

  const formreset= () => {
    console.log("Reset work");
    document.getElementById('username').value = "";
    document.getElementById('useremail').value = "";
    document.getElementById('usercontact').value = "";
    document.getElementById('userinterest').value = "";
    document.getElementById('usermessage').value = "";
  }

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('dharmender', 'helperadda', form.current, 'zxsfDm0Yu9qguwoMo');
    document.getElementById('SuccesFullySend').style.display = 'flex'
    formreset();      
  };
  return (
    <div className="container form__container" data-aos="fade-right">
      <div className="centre-heading mb-3">
        <div className="top-heading">
          <h5>Get in Touch</h5>
        </div> 
        <h1>Send us a message and we’ll get back to you shortly</h1>
      </div>
      <div className="form_section" data-aos="fade-right">
        <div className="form__content">
          <img src={ContactImg} alt="Helper Adda Contact" />
          <div className="contact_detail">
            <article>
              <h2>Contact Us</h2>
              <div className="contact-footer-info">
                <div className="contact-info">
                  <img src={Location} alt="Location icon" className='contact-icons' />
                  <p><strong>{ContactDetails[0].OfficeType1}</strong>{ContactDetails[0].Address1}</p>
                </div>                
                <div className="contact-info">
                  <img src={Call} alt="call icon" className='contact-icons' />
                  <p><strong>{ContactDetails[0].ContactHeading}</strong>{ContactDetails[0].ContactNumber}</p>
                </div>
                <div className="contact-info">
                  <img src={Emailicon} alt="Email icon" className='contact-icons' />
                  <p><strong>{ContactDetails[0].MailHeading}</strong>{ContactDetails[0].MailInfo}</p>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className="form_main">
          <form ref={form} onSubmit={sendEmail}>
            <div className="form_input">
              <input type="text" placeholder="Enter Full Name" id="username" name="user_name" required />
            </div>
            <div className="form_input">
              <input type="email" placeholder="Enter Email Address" id="useremail" name="user_email" required/>
            </div>
            <div className="form_input">
              <input type="number" placeholder="Enter Contact Number" id="usercontact" name="user_contact" required/>
            </div>
            <div className="form_input">
              <input type="text" placeholder="Interested Services Name" id="userinterest" name="user_service" required/>
            </div>
            <div className="form_input">
              <textarea name="user_message" id="usermessage" cols="30" rows="6" placeholder="Enter Message here ..." required></textarea>
            </div>
            <div className="form_button">
              <input type="submit" name="" className="submit-btn" id="" value="Send"/>
              <div className="SuccesFullySend" id="SuccesFullySend">
                Your Message Successfully Send !
              </div>
            </div>
          </form>
          <div className="privacy_content">
            <p><span>Helper Adda  </span>uses the contact information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time.</p>
            <p>For information, check out our <span><Link to="/privacy-policy" onClick={NavigateClick}>Privacy Policy</Link></span></p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ContactFormSection;

import { Link } from 'react-router-dom'
import { Nav_Service_Link,Others_Link, SocialHandles, ContactDetails } from "../../data";
import { AiFillInstagram } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
// import { AiOutlineTwitter } from 'react-icons/ai'
import { BiLogoFacebook } from 'react-icons/bi'
// import { BiLogoLinkedin } from 'react-icons/bi'
import Logo_white from "../../assets/images/logo-white.svg"
import Playstore from "../../assets/images/footer-playstore.webp"
import Appstore from "../../assets/images/footer-appstore.webp"
import Location from "../../assets/icons/location.svg"
import Call from "../../assets/icons/call.svg"
import Emailicon from "../../assets/icons/email.svg"
import TopUp from '../TopUp/TopUp'
import './footer.css'

const CommingSoon = () => {
  alert('Helper Adda is Comming soon on App Store');
};

const Footer = () => {
  const NavigateClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer>
        <div className="container footer__container">
          <article>
            <Link to="/" className='logo footer-logo'>
              <img src={Logo_white} alt="Footer Logo" />
            </Link>
            <p>
            Helper adda is powered by Shailasia Management Pvt Ltd, <br /> It revolutionizes the domestic service industry in India by offering a unique and convenient platform for hiring professional and trained domestic helpers. With Helper Adda, you can easily find and hire a wide range of skilled professionals.
            </p>
            <div className="footer__Others">
              <h4 className='imp_heading'>Others Links</h4>
              <ul className='other-list'>
                {
                  Others_Link.map(({ name, path }, index) => {
                    return (
                      <li key={index}>
                        <Link to={path} className='oth__links' onClick={NavigateClick} target="_blank">{name}</Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className="download__store">
              <Link to="https://play.google.com/store/apps/details?id=com.helperaddda.app" className='logo' target='_blank'>
                <img src={Playstore} alt="Playstore Logo" />
              </Link>
              <Link onClick={CommingSoon} className='logo'>
                <img src={Appstore} alt="Appstore Logo" />
              </Link>
            </div>
            <div className="footer__social">
              <a href={SocialHandles[0].HrefLink} target='_blank' rel="noreferrer" alt={SocialHandles[0].Name}><AiFillYoutube /></a>
              {/* <a href={SocialHandles[1].HrefLink} target='_blank' rel="noreferrer" alt={SocialHandles[1].Name}><BiLogoLinkedin /></a> */}
              <a href={SocialHandles[2].HrefLink} target='_blank' rel="noreferrer" alt={SocialHandles[2].Name}><AiFillInstagram /></a>
              <a href={SocialHandles[3].HrefLink} target='_blank' rel="noreferrer" alt={SocialHandles[3].Name}><BiLogoFacebook /></a>
              {/* <a href={SocialHandles[4].HrefLink} target='_blank' rel="noreferrer" alt={SocialHandles[4].Name}><AiOutlineTwitter /></a> */}
            </div>
          </article>

          <article>
            <h4 className='imp_heading'>Important Links</h4>
            <ul className=''>
              {
                Nav_Service_Link.map(({ name, path }, index) => {
                  return (
                    <li key={index}>
                      <Link to={path} className='imp__links' onClick={NavigateClick}>{name}</Link>
                    </li>
                  )
                })
              }
            </ul>
          </article>

          <article>
            <h4>Contact Us</h4>
            <div className="contact-footer-info">
              <div className="contact-info margin_10">
                <img src={Location} alt="call icon" className='contact-icons-add' />
                <p>{ContactDetails[0].Address1}</p>
              </div>
              <div className="contact-info margin_10">
                <img src={Location} alt="call icon" className='contact-icons-add' />
                <p>{ContactDetails[0].Address2}</p>
              </div>
              <div className="contact-info margin_10">
                <img src={Call} alt="call icon" className='contact-icons' />
                <Link to="tel:9818699556" >{ContactDetails[0].ContactNumber}</Link>
              </div>
              <div className="contact-info margin_10">
                <img src={Emailicon} alt="call icon" className='contact-icons' />
                <Link to="mailto:support@helperadda.com" >{ContactDetails[0].MailInfo}</Link>
              </div>
            </div>

          </article>
        </div>

        <div className="footer__copyright">
          <p>Copyright © Helper Adda 2023. All Rights Reserved</p>
        </div>
      </footer>
      <TopUp />
    </>
  )
}

export default Footer
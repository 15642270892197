import "./app_store.css";
import { Link } from "react-router-dom";
import AndroidAppImg from "../../assets/images/download_app/andriod_app.webp";
import IosAppImg from "../../assets/images/download_app/iso_app.webp";
import AndroidQrCode from "../../assets/images/download_app/andriod_qr.webp";
import IosQrCode from "../../assets/images/download_app/ios_qr.webp";

const AppStore = () => {
  const CommingSoon = () => {
    alert('Helper Adda is Comming soon on App Store');
  };

  return (
    <div className="container app_section">
      <div className="app_store">
        <div className="android_store" data-aos="fade-right">
          <div className="logo_img">
            <img src={AndroidAppImg} alt="AndroidAppImg" />
          </div>
          <div className="download_button">
            <Link
              to="https://play.google.com/store/apps/details?id=com.helperaddda.app"
              className="button"
              target="_blank"
            >
              Download Now
            </Link>
          </div>
          <div className="code_img">
            <img src={AndroidQrCode} alt="AndroidQrCode" />
          </div>
        </div>
        <div className="android_store" data-aos="fade-right">
          <div className="logo_img">
            <img src={IosAppImg} alt="IosAppImg" />
          </div>
          <div className="download_button">
            <Link
              onClick={CommingSoon}
              className="button"
            >
              Download Now
            </Link>
          </div>
          <div className="code_img">
            <img src={IosQrCode} alt="IosQrCode" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppStore;

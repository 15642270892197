// import {SiOpenaigym} from 'react-icons/si'
const DomainBaseUrl = "https://helperadda.com/helper_adda_images/icons/services-icons";
const DomaintestimonialUrl = "https://helperadda.com/helper_adda_images/testimonials";
const ServicesUrl = "https://helperadda.com/helper_adda_images/services_img";

export const links = [
    {
        name:"Home",
        path: '/'
    },
    {
        name:"About",
        path: '/about'
    },
    {
        name:"Our Services",
        path: '/services'
    },
    {
        name:"Service Provider",
        path: '/service-provider'
    },
    {
        name:"Contact",
        path: '/contact'
    },  
]

export const DownloadLinks = [
    {
        name:"Playstore",
        url: "https://play.google.com/store/apps/details?id=com.helperaddda.app"
    },
    {
        name:"Appstore",
        url: "https://play.google.com/store/apps/details?id=com.helperaddda.app"
    }
]

export const Services = [
    {
        name:"Cook Service",
        srclink:`${DomainBaseUrl}/CookService.png`,
        path:'/services'
    },
    {
        name:"Nurse Service",
        srclink:`${DomainBaseUrl}/NurseService.png`,
        path:'/services'
    },
    {
        name:"Buddy Service",
        srclink:`${DomainBaseUrl}/BuddyService.png`,
        path:'/services'
    },
    {
        name:"Event Planner",
        srclink:`${DomainBaseUrl}/EventPlannerService.png`,
        path:'/services'
    },
    {
        name:"Massager Service",
        srclink:`${DomainBaseUrl}/MassagerService.png`,
        path:'/services'
    },
    {
        name:"Pregnancy Care",
        srclink:`${DomainBaseUrl}/PregnancyCareService.png`,
        path:'/services'
    },
    {
        name:"Baby Sitter",
        srclink:`${DomainBaseUrl}/BabySitterService.png`,
        path:'/services'
    },
    {
        name:"Office Boy Service",
        srclink:`${DomainBaseUrl}/OfficeBoyService.png`,
        path:'/services'
    }, 
]

export const ContactDetails = [
    {
        OfficeType1:"Cooperate Office : ",
        Address1:"OC 827-830, 8th floor, Gaur City centre, Greater Noida West, Uttar Pradesh - 201301",        
        Address2:"Registered Address : 500 ITL Tower, Netaji Subhash Place, Pitampura, new delhi 110034",        
        ContactHeading:"Contact Number : ",
        ContactNumber:"+91 9319194642, +91 9319194645",
        // ContactNumber:"+91 9518268109, +91 1204449772",
        MailHeading:"Email Address : ",
        MailInfo:"support@helperadda.com",
    }
]

export const OurCustomers = [
    {
        NewsType: 1,
        classN: "LatestNews",
        name: "Our Customers",
        Heading: "Event - Planning  by Mr. Aryan Kumar",
        path: '/',
        ImgSrc: `${DomaintestimonialUrl}/testimonial_01.svg`,
        disc: "I was looking for a job for a long time, but I could not find a safe and secure job for some reason. But after some time, I heard about Helper Adda and contacted them. Now. I don't need to worry about my job. I get paid on the same day by them, and I didn't think about earning money 10k per month, but after joining Helper Adda, I used to make 25k-30k per month, and I have no burden on myself."
        
    },
    {
        NewsType: 2,
        classN: "RecentLaunch",
        name: "Our Service Providers",
        Heading: "Event - Planning  by Mr. Neelam Panday",
        path: '/products/turn-table-parking',
        ImgSrc: `${DomaintestimonialUrl}/testimonial_01.svg`,
        disc: "I was in the house party with my family and at the last moment my maid gone and i was litrally tired but wanted to wrap up all the things but meanwhile i hired a maid from Helper Adda and then they send the maid in just few minutes and she was very professional and did great job. i highly recommend them and i am hoping to have the service again with them.."
    },
]

export const ServicePagesSecSecond = [
    {
        ProdId: 1,
        SecTop: "Cook Service",
        SecDesc: "Does your busy schedule stop you from having cooked meals? We have got you right!! Skip the hassle of cooking and hire a professional cook in just a few minutes",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/cook_img.png`,
    },
    {
        ProdId: 2,
        SecTop: "Nurse Service",
        SecDesc: "If you face difficulties caring for a patient at home, you can hire a professional nurse with Helper Adda. To reduce the risk of surgical patients, we provide nursing services to heal the patient faster with less infection.",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/nurse_img.png`,
    },
    {
        ProdId: 3,
        SecTop: "Buddy Service",
        SecDesc: "Welcome To Helper Adda, for any occasion, anywhere and at anytime, we offer around-the-clock support to our customers. Emergencies can occur anywhere and anytime, so we have come up with quick and efficient solutions.",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/buddy_img.png`,
    },
    {
        ProdId: 4,
        SecTop: "Event Planner Service",
        SecDesc: "Are you planning an important celebration and don't know how to manage it? Then you are at the right place! We HelperAdda organizes house parties, house decorations, home parties, and many more.",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/event_img.png`,
    },

    {
        ProdId: 5,
        SecTop: "Massager Service",
        SecDesc: "If you are feeling depressed or have pain in your neck, shoulder, legs, and back, then you must bring a professional massager with Helper Adda to your home/office.",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/message_img.png`,
    },
    {
        ProdId: 6,
        SecTop: "Pregnancy Care Service",
        SecDesc: "A busy schedule can sometimes create a problem for a pregnant lady.Due to the fact that both the mother and unborn child depend on someone for care and feeding, you need a pregnancy care service.",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/pregency_img.png`,
    },
    {
        ProdId: 7,
        SecTop: "Baby Sitter Service",
        SecDesc: "Helper Adda babysitters are highly skilled, and we have done all the background checks, like criminal records. We guarantee, you will get the highest standards set by all parents. We satisfy the needs of both kids and their parents.",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/child_img.png`,
    },
    {
        ProdId: 8,
        SecTop: "Office Boy Service",
        SecDesc: "Helper Adda provides the best office assistant services. They are highly skilled and well-trained professionals who help to manage and organize office duties. We provide domestic services that are highly trusted and deliver great performance all over.",
        Btn1Name: "Read More",
        Btn1URL: "/download",        
        Btn2URL: "",
        SecOneImg: `${ServicesUrl}/maid_img.png`,
    },    
]

// For Footer
export const Nav_Service_Link = [
    {
        name: "About Us",
        path: "/about"
    },
    {
        name: "Our Services",
        path: "/services"
    },
    {
        name: "Service Provider",
        path: "/service-provider"
    },
    {
        name: "Download App",
        path: "/download"
    },
    {
        name: "Contact Us",
        path: "/contact"
    },
    {
        name: "Review us",
        path: "https://www.google.com/search?q=helper+adda&oq=helper+adda&aqs=chrome..69i57j0i22i30j0i390i650l3j69i61l2j69i60.2630j0j7&sourceid=chrome&ie=UTF-8#lrd=0x2e03b3d0e7fe105f:0xfb032138f852cbfa,3,,,,"
    },
]
export const Others_Link = [
    
    {
        name: "Privacy Policy",
        path: "/privacy-policy"
    },
    {
        name: "Refund Policy",
        path: "/refund-policy"
    },
    {
        name: "Terms & Conditions",
        path: "/terms-conditions"
    },
   
]
export const SocialHandles = [
    {
        id:1,
        Name:"Youtube",
        HrefLink:"https://www.youtube.com/@merahelperadda",
    },
    {
        id:2,
        Name:"Linkedin",
        HrefLink:"https://www.linkedin.com"
    },
    {
        id:3,
        Name:"Instagram",
        HrefLink:"https://www.instagram.com/merahelperadda/"
    },
    {
        id:4,
        Name:"Facebook",
        HrefLink:"https://www.facebook.com/merahelperadda/"
    },
    {
        id:5,
        Name:"Twitter",
        HrefLink:"https://www.twitter.com"
    },
]
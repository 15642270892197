import React, { StrictMode } from "react";
// =======components ========
import HomeHero from "../../ui/home_components/HomeHero";
import HomeAbout from "../../ui/home_components/HomeAbout";
import HomeServices from "../../ui/home_components/HomeServices";
import WhyChooseUs from "../../ui/services_components/why_choose_us_components";
import WorkProcess from "../../ui/home_components/WorkProcess";
import LogoRegister from "../../ui/home_components/LogoRegister";
import BackedBy from "../../ui/home_components/BackedBy";
// import OurCustomer from "../../ui/about_components/our_customer_components";
import HomeBookDemo from "../../ui/service_provider_components/HomeBookDemo";
import MetaLogic from '../../metalogic'


const Home = () => {
  return (
    <StrictMode>
      <MetaLogic title={"India's First Real-Time Domestic Help Provider. Hire Cooks, Maids etc Now!"} description={"Helper Adda: Hire the best domestic help, fully prepared for your home. Flexible, convenient, and reliable services for peace of mind"} canonicalUrl={"https://helperadda.com"} />
      {/* <div id="google_translate_element"></div> */}
      <HomeHero name="Helper Adda" desc="Discover a single, comprehensive resource that caters to all your household needs, simplifying your life and ensuring your home runs smoothly. A one-stop solution for all your needs and Helper Adda will locate the Best Professionals in your area."/>
      <HomeAbout title="About our Company" name="" desc_1="Helper adda is powered by Shailasia Management Pvt Ltd, It is India's first real-time domestic service provider. Helper Adda is a one-stop solution for all your household needs from where you can hire professional & trained domestic helpers, including maids, babysitters, massagers, drivers, buddies 24*7, nurses, cleaners, pregnancy care, event planners and so on, for your assistance in a few minutes that is easy, quick and reliable."/>
      <HomeServices title="OUR SERVICES" heading="What services we offer!" />
      <WhyChooseUs title="Why Choose Us"/>
      <WorkProcess/>
      <LogoRegister/>
      <BackedBy/>
      {/* <OurCustomer title="Our Customers" next="View Next" /> */}
      <HomeBookDemo
        desc="We'd like to extend an invitation to you to see Helper Adda in action at your earliest convenience. With you, our professionals will go over the key features and functions and address any queries you may have.
        Please let us know which day and time are most convenient for you, so that we can make the necessary arrangements."
        title="Want to join us as services provider?"
        heading="Let's Scheduled a demo!"
        contact_no="+91 9518268109"
      />
    </StrictMode>
  );
};
export default Home;

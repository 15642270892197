// import ReactDOM from 'react-dom/client'
import { hydrate, render } from "react-dom";
import App from './App'
import './index.css'

// const root = ReactDOM.createRoot(document.querySelector('#root'));
// root.render(<App/>)


const Application = (
    <App/>
);
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Application, rootElement);
} else {
  render(Application, rootElement);
}
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import ServicesProvider from './pages/serviceProvider/ServiceProvider'
import Home from './pages/home/Home'
import About from './pages/about/About'
import Services from './pages/services/Services'
import Contact from './pages/contact/Contact'
import Download from './pages/download/Download'
import PrivacyPolicy from './pages/Poilicies/PrivacyPolicy'
import RefundPolicy from './pages/Poilicies/RefundPolicy'
import TermsConditions from './pages/Poilicies/TermsConditions'
import NotFound from './pages/notFound/NotFound'
import CustomBtnFunctionality from './components/custonBtnFunctionality'
import BtnFunctionality from "../src/components/BtnFunctionality/BtnFunctionality";
import MetaLogic from './metalogic'

// import DynamicTitle from './components/DynamicTitle';
// import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// ..
AOS.init({offset: 100,duration: 1000, once: true,});

const App = () => {
  // const DefaultWebsiteTitle = 'Helper Adda | Best Domestic Real Time Services in India';
  // const DefaultWebsiteTitleDescription = "Helper Adda is India's first real-time domestic service provider. Helper Adda is a one-stop solution for all your household needs from where you can hire professional & trained domestic helpers, including Maids, Babysitters, Massagers, Drivers, Buddies 24*7, Nurses and etc.";

  return (
    <BrowserRouter>
      <Navbar/>
      <MetaLogic title={"India's First Real-Time Domestic Help Provider. Hire Cooks, Maids etc Now!"} description={"Helper Adda: Hire the best domestic help, fully prepared for your home. Flexible, convenient, and reliable services for peace of mind"} canonicalUrl={"https://helperadda.com"} />
      <Routes>
        <Route path='/' element = {<Home/>}/>
        <Route path='about' element = {<About/>}/>
        <Route path='services' element = {<Services/>}/>
        <Route path='service-provider' element = {<ServicesProvider/>}/>
        <Route path='contact' element = {<Contact/>}/>
        <Route path='download' element = {<Download/>}/>
        <Route path='privacy-policy' element = {<PrivacyPolicy/>}/>
        <Route path='refund-policy' element = {<RefundPolicy/>}/>
        <Route path='terms-conditions' element = {<TermsConditions/>}/>
        <Route path='*' element = {<NotFound/>}/>
      </Routes>
      <CustomBtnFunctionality/>
      <Footer/>
      <BtnFunctionality/>
    </BrowserRouter>
  )
}

export default App